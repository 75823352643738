<template>
  <div>
    <div class="table p-6 mx-auto" v-if="!loaded">
      <spinner size="w-8 h-8" class="align-sub text-primary" />
    </div>
    <section class="px-6 py-2 space-y-6" v-else>
      <Tabs
        :component_name="component_name"
        :tabs="tabs"
        @action="component_name = $event"
        link_type="button"
      />
      <main>
        <slide-transition>
          <div class="space-y-4" v-show="component_name === 'email_setup'">
            <figure class="w-full rounded bg-white ring-1 ring-slate-200">
              <blockquote class="p-6">
                <form>
                  <ul class="space-y-4">
                    <li>
                      <div class="form-group-with-check">
                        <div>
                          <input
                            type="radio"
                            name="emailConfig"
                            id="default"
                            value="0"
                            v-model.number="smtp_data.custom_smtp"
                          />
                        </div>
                        <label for="default">
                          Default email
                          <a
                            href="javascript:void(0)"
                            class="text-xs bg-slate-100 rounded border border-slate-300 font-medium py-0.5 px-1.5"
                          >
                            &lt;no-reply@Merchandise.com&gt;
                          </a>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group-with-check">
                        <div>
                          <input
                            type="radio"
                            name="emailConfig"
                            id="custom"
                            value="1"
                            v-model.number="smtp_data.custom_smtp"
                          />
                        </div>
                        <label for="custom"> Custom email </label>
                      </div>
                      <!--  -->
                      <slide-transition>
                        <div
                          class="mt-4 space-y-3"
                          v-if="smtp_data.custom_smtp"
                        >
                          <div class="form-group">
                            <div class="with-label">
                              <p class="error" v-if="smtp_errors.smtp_username">
                                {{ smtp_errors.smtp_username.join(' ') }}
                              </p>
                              <input
                                type="email"
                                name="username"
                                id="username"
                                placeholder="example@example.com"
                                v-email
                                v-model="smtp_data.smtp_username"
                              />
                              <label for="username">{{ $t('username') }}</label>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="with-label">
                              <p class="error" v-if="smtp_errors.smtp_from">
                                {{ smtp_errors.smtp_from.join(' ') }}
                              </p>
                              <input
                                type="email"
                                name="from"
                                id="from"
                                placeholder="example@example.com"
                                v-email
                                v-model="smtp_data.smtp_from"
                              />
                              <label for="from">{{ $t('from') }}</label>
                            </div>
                          </div>

                          <div class="actions-group !gap-4">
                            <div class="flex-[max-content]">
                              <div class="form-group">
                                <div class="with-label">
                                  <p class="error" v-if="smtp_errors.smtp_host">
                                    {{ smtp_errors.smtp_host.join(' ') }}
                                  </p>
                                  <input
                                    type="text"
                                    name="smtp"
                                    id="smtp"
                                    placeholder="example.com"
                                    v-model="smtp_data.smtp_host"
                                  />
                                  <label for="smtp">SMTP</label>
                                </div>
                              </div>
                            </div>
                            <div class="flex-[max-content]">
                              <div class="form-group">
                                <div class="with-label">
                                  <p class="error" v-if="smtp_errors.smtp_port">
                                    {{ smtp_errors.smtp_port.join(' ') }}
                                  </p>
                                  <input
                                    type="number"
                                    name="port"
                                    id="port"
                                    placeholder="000"
                                    v-model="smtp_data.smtp_port"
                                  />
                                  <label for="port">PORT</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="with-label">
                              <p class="error" v-if="smtp_errors.smtp_protocol">
                                {{ smtp_errors.smtp_protocol.join(' ') }}
                              </p>
                              <select
                                v-model="smtp_data.smtp_protocol"
                                class="custom-select"
                              >
                                <option value="" disabled selected>
                                  {{ $t('select') }}
                                </option>
                                <option value="SSL">SSL</option>
                                <option value="TLS">TLS</option>
                              </select>
                              <label for="password">SMTP Protocol</label>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="with-label">
                              <p class="error" v-if="smtp_errors.smtp_password">
                                {{ smtp_errors.smtp_password.join(' ') }}
                              </p>
                              <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="••••••••"
                                v-model="smtp_data.smtp_password"
                              />
                              <label for="password">{{ $t('password') }}</label>
                            </div>
                          </div>
                        </div>
                      </slide-transition>
                      <!--  -->
                    </li>
                  </ul>
                </form>
              </blockquote>
            </figure>
            <button
              class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
              @click.prevent="updateEmailSetup"
              :disabled="setup_disabled"
            >
              <spinner size="w-4 h-4 inline-block" v-if="setup_disabled" />
              {{ $t('save_changes') }}
            </button>
          </div>
        </slide-transition>
        <slide-transition>
          <div class="space-y-4" v-show="component_name === 'reports'">
            <figure class="w-full rounded bg-white ring-1 ring-slate-200">
              <blockquote class="pb-6 overflow-y-auto">
                <form>
                  <div class="overflow-x-auto">
                    <table class="w-full divide-y divide-slate-200">
                      <thead class="divide-y divide-slate-200">
                        <tr>
                          <th
                            class="text-sm font-semibold rtl:font-bold text-slate-600 p-3 text-center"
                          >
                            <p>{{ $t('daily') }}</p>
                          </th>
                          <th
                            class="text-sm font-semibold rtl:font-bold text-slate-600 p-3 text-center"
                          >
                            <p>{{ $t('weekly') }}</p>
                          </th>
                          <th
                            class="text-sm font-semibold rtl:font-bold text-slate-600 p-3 text-center"
                          >
                            <p>{{ $t('monthly') }}</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-slate-200">
                        <tr class="divide-x divide-slate-200">
                          <td class="p-3">
                            <ul class="space-y-4">
                              <li
                                v-for="(report, index) in filterReports(
                                  'daily'
                                )"
                                :key="index"
                              >
                                <div class="form-group-with-check">
                                  <div>
                                    <input
                                      type="checkbox"
                                      :name="`report_daily_${index}`"
                                      :id="`report_daily_${index}`"
                                      :value="report.key"
                                      v-model="reports_data.daily"
                                    />
                                  </div>
                                  <label :for="`report_daily_${index}`">
                                    {{
                                      report.translate_key
                                        ? $t(report.translate_key)
                                        : report.name
                                    }}
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </td>
                          <td class="p-3">
                            <ul class="space-y-4">
                              <li
                                v-for="(report, index) in filterReports(
                                  'weekly'
                                )"
                                :key="index"
                              >
                                <div class="form-group-with-check">
                                  <div>
                                    <input
                                      type="checkbox"
                                      :name="`report_weekly_${index}`"
                                      :id="`report_weekly_${index}`"
                                      :value="report.key"
                                      v-model="reports_data.weekly"
                                    />
                                  </div>
                                  <label :for="`report_weekly_${index}`">
                                    {{
                                      report.translate_key
                                        ? $t(report.translate_key)
                                        : report.name
                                    }}
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </td>
                          <td class="p-3">
                            <ul class="space-y-4">
                              <li
                                v-for="(report, index) in filterReports(
                                  'monthly'
                                )"
                                :key="index"
                              >
                                <div class="form-group-with-check">
                                  <div>
                                    <input
                                      type="checkbox"
                                      :name="`report_monthly_${index}`"
                                      :id="`report_monthly_${index}`"
                                      :value="report.key"
                                      v-model="reports_data.monthly"
                                    />
                                  </div>
                                  <label :for="`report_monthly_${index}`">
                                    {{
                                      report.translate_key
                                        ? $t(report.translate_key)
                                        : report.name
                                    }}
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
              </blockquote>
            </figure>
            <button
              class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
              @click.prevent="updateEmailReports"
              :disabled="reports_disabled"
            >
              <spinner size="w-4 h-4 inline-block" v-if="reports_disabled" />
              {{ $t('save_changes') }}
            </button>
          </div>
        </slide-transition>
        <slide-transition>
          <div class="space-y-4" v-show="component_name === 'emails'">
            <div class="form-group space-y-2">
              <label for="emails">Emails</label>
              <div class="flex gap-4">
                <v-tags
                  v-model="email"
                  :tags="email_data.emails"
                  placeholder="eg: example@example.com"
                  class="v-tags flex-1"
                  @tags-changed="(newTags) => (email_data.emails = newTags)"
                />
                <button
                  class="py-2 px-4 text-sm font-medium text-white bg-primary rounded"
                  type="button"
                  @click.prevent="updateEmails"
                  :disabled="email_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="email_disabled" />
                  {{ $t('submit') }}
                </button>
              </div>

              <p
                class="text-red-500 text-sm"
                v-if="Object.values(email_errors || {}).length"
                v-html="Object.values(email_errors || {}).join('<br />')"
              ></p>
            </div>
            <figure class="w-full rounded bg-white ring-1 ring-slate-200">
              <figcaption class="p-4 border-b border-b-slate-200">
                <div class="flex items-center gap-4">
                  <div class="flex-1">
                    <p
                      class="text-sm font-semibold rtl:font-bold text-slate-600 line-clamp-1"
                    >
                      {{ $t('emails') }}
                    </p>
                  </div>
                  <div>
                    <p
                      class="text-slate-600 font-medium rtl:font-semibold text-sm"
                    >
                      <span
                        >{{ $t('selected') }} ({{
                          emails_data.emails.length
                        }})</span
                      >
                    </p>
                  </div>
                </div>
              </figcaption>
              <blockquote class="pb-6 overflow-y-auto max-h-[30rem]">
                <form>
                  <ul class="space-y-4">
                    <li>
                      <div class="overflow-x-auto">
                        <table class="w-full divide-y divide-slate-200">
                          <thead class="divide-y divide-slate-200">
                            <tr>
                              <th
                                class="text-sm font-semibold rtl:font-bold text-slate-600 p-3 text-center"
                              >
                                <p>{{ $t('select_all') }}</p>
                              </th>
                              <th
                                class="text-sm font-semibold rtl:font-bold text-slate-600 p-3"
                              >
                                {{ $t('user') }}
                              </th>
                              <th
                                class="text-sm font-semibold rtl:font-bold text-slate-600 p-3 text-center"
                              >
                                <p>{{ $t('daily') }}</p>
                              </th>
                              <th
                                class="text-sm font-semibold rtl:font-bold text-slate-600 p-3 text-center"
                              >
                                <p>{{ $t('weekly') }}</p>
                              </th>
                              <th
                                class="text-sm font-semibold rtl:font-bold text-slate-600 p-3 text-center"
                              >
                                <p>{{ $t('monthly') }}</p>
                              </th>
                            </tr>
                            <tr>
                              <th class="p-3">
                                <div
                                  class="form-group-with-check justify-center"
                                >
                                  <input
                                    type="checkbox"
                                    name="checkAllUsers"
                                    id="checkAllUsers"
                                    @change.prevent="handleChangeAll"
                                    :checked="
                                      (emails_data.emails || []).every((e) =>
                                        emails_data.emails
                                          .map((u) => u.id)
                                          .includes(e.id)
                                      )
                                    "
                                  />
                                </div>
                              </th>
                              <th class="p-3"></th>
                              <th class="p-3">
                                <div
                                  class="form-group-with-check justify-center"
                                >
                                  <input
                                    type="checkbox"
                                    name="checkAllDaily"
                                    id="checkAllDaily"
                                    @change.prevent="
                                      handleChangeAll($event, 'daily')
                                    "
                                    :checked="
                                      (emails_data.emails || []).every(
                                        (e) =>
                                          emails_data.emails
                                            .map((u) => u.id)
                                            .includes(e.id) &&
                                          emails_data.emails.every(
                                            (u) => u.daily
                                          )
                                      )
                                    "
                                  />
                                </div>
                              </th>
                              <th class="p-3">
                                <div
                                  class="form-group-with-check justify-center"
                                >
                                  <input
                                    type="checkbox"
                                    name="checkAllWeekly"
                                    id="checkAllWeekly"
                                    @change.prevent="
                                      handleChangeAll($event, 'weekly')
                                    "
                                    :checked="
                                      (emails_data.emails || []).every(
                                        (e) =>
                                          emails_data.emails
                                            .map((u) => u.id)
                                            .includes(e.id) &&
                                          emails_data.emails.every(
                                            (u) => u.weekly
                                          )
                                      )
                                    "
                                  />
                                </div>
                              </th>
                              <th class="p-3">
                                <div
                                  class="form-group-with-check justify-center"
                                >
                                  <input
                                    type="checkbox"
                                    name="checkAllMonthly"
                                    id="checkAllMonthly"
                                    @change.prevent="
                                      handleChangeAll($event, 'monthly')
                                    "
                                    :checked="
                                      (emails_data.emails || []).every(
                                        (e) =>
                                          emails_data.emails
                                            .map((u) => u.id)
                                            .includes(e.id) &&
                                          emails_data.emails.every(
                                            (u) => u.monthly
                                          )
                                      )
                                    "
                                  />
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            class="divide-y divide-slate-200"
                            v-if="!loaded"
                          >
                            <tr v-for="i in 6" :key="i">
                              <td v-for="loader in 5" :key="loader" class="p-3">
                                <div
                                  class="h-4 rounded-md bg-slate-200 w-24 animate-pulse"
                                ></div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody class="divide-y divide-slate-200">
                            <tr
                              class="divide-x divide-slate-200"
                              v-for="(item, index) in emails"
                              :key="index"
                            >
                              <td class="p-3">
                                <div
                                  class="form-group-with-check justify-center"
                                >
                                  <input
                                    type="checkbox"
                                    :name="`TypeGroup_${item.id}`"
                                    :value="item.id"
                                    :checked="
                                      emails_data.emails.some(
                                        (e) => e.id === item.id
                                      )
                                    "
                                    @change.prevent="
                                      handleUserChange(
                                        $event,
                                        undefined,
                                        item.id
                                      )
                                    "
                                    :disabled="!item.is_active"
                                    :id="`type_${item.id}`"
                                  />
                                </div>
                              </td>
                              <td class="p-3">
                                <a
                                  class="text-xs font-medium text-sky-600 inline-flex gap-2 line-clamp-1"
                                  :href="
                                    item.email
                                      ? `mailto:${item.email}`
                                      : 'javascript:void(0)'
                                  "
                                >
                                  <span>{{ item.email || '-' }}</span>

                                  <span
                                    class="font-medium text-center inline-block"
                                    :class="
                                      item.is_active
                                        ? 'text-sky-600'
                                        : 'text-red-500'
                                    "
                                  >
                                    {{
                                      item.is_active ? 'Active' : 'Not active'
                                    }}</span
                                  >
                                </a>
                              </td>
                              <td class="p-3">
                                <div
                                  class="form-group-with-check justify-center"
                                >
                                  <input
                                    type="checkbox"
                                    :name="`TypeGroup_daily_${item.id}`"
                                    :id="`type_${item.id}_1`"
                                    :true-value="1"
                                    :false-value="0"
                                    :checked="
                                      emails_data.emails.some(
                                        (e) => e.id === item.id && e.daily
                                      )
                                    "
                                    @change.prevent="
                                      handleUserChange($event, 'daily', item.id)
                                    "
                                  />
                                </div>
                              </td>
                              <td class="p-3">
                                <div
                                  class="form-group-with-check justify-center"
                                >
                                  <input
                                    type="checkbox"
                                    :name="`TypeGroup_weekly_${item.id}`"
                                    :id="`type_${item.id}_2`"
                                    :true-value="1"
                                    :false-value="0"
                                    :checked="
                                      emails_data.emails.some(
                                        (e) => item.id === e.id && e.weekly
                                      )
                                    "
                                    @change.prevent="
                                      handleUserChange(
                                        $event,
                                        'weekly',
                                        item.id
                                      )
                                    "
                                  />
                                </div>
                              </td>
                              <td class="p-3">
                                <div
                                  class="form-group-with-check justify-center"
                                >
                                  <input
                                    type="checkbox"
                                    :name="`TypeGroup_monthly_${item.id}`"
                                    :id="`type_${item.id}_3`"
                                    :true-value="1"
                                    :false-value="0"
                                    :checked="
                                      emails_data.emails.some(
                                        (e) => item.id === e.id && e.monthly
                                      )
                                    "
                                    @change.prevent="
                                      handleUserChange(
                                        $event,
                                        'monthly',
                                        item.id
                                      )
                                    "
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                  </ul>
                </form>
              </blockquote>
            </figure>
            <button
              class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
              @click.prevent="updateEmailUsers"
              :disabled="users_disabled"
            >
              <spinner size="w-4 h-4 inline-block" v-if="users_disabled" />
              {{ $t('save_changes') }}
            </button>
          </div>
        </slide-transition>
      </main>
    </section>
  </div>
</template>

<script>
import Modal from '@/components/basics/Modal.vue'
import { mapActions, mapGetters } from 'vuex'
import Tabs from '@/components/basics/Tabs.vue'
export default {
  name: 'EmailConfiguration',
  components: { Modal, Tabs },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
  },
  data() {
    return {
      add_data: {
        is_default: 1,
        to_emails: [],
      },
      emails: [],
      loaded: false,
      component_name: 'email_setup',
      tabs: [
        {
          title: 'Email setup',
          name: 'email_setup',
          key: 'email_setup',
        },
        {
          title: 'Reports',
          name: 'reports',
          key: 'reports',
        },
        {
          title: 'Emails',
          name: 'emails',
          key: 'emails',
        },
      ],
      reports: [
        {
          name: 'Visits',
          translate_key: 'visits',
          key: 'visits',
          permission: 'visits',
        },
        {
          name: 'performance',
          translate_key: 'performance',
          key: 'performance',
          permission: 'performance',
        },
        {
          name: 'OSA reports',
          translate_key: 'osa_reports',
          key: 'osa_reports',
          permission: 'osa_reports',
        },
        {
          name: 'Skus',

          translate_key: 'skus',
          key: 'sku_audits',
          permission: 'sku_audits',
        },
        {
          name: 'Expires',

          translate_key: 'expiries',
          key: 'expiry_audits',
          permission: 'expiry_audits',
        },

        {
          name: 'Stock ',

          translate_key: 'stock_audits',
          key: 'stock_audits',
          permission: 'stock_audits',
        },
        {
          name: 'Delivery',

          translate_key: 'delivery_audits',
          key: 'delivery_audits',
          permission: 'delivery_audits',
        },

        {
          name: 'Shelf ',

          translate_key: 'shelf_audits',
          key: 'shelf_audits',
          permission: 'shelf_audits',
        },
        {
          name: 'Returns',

          translate_key: 'returns',
          key: 'return_audits',
          permission: 'return_audits',
        },
        {
          name: 'Promotion',

          translate_key: 'promotion',
          key: 'promotion_audits',
          permission: 'promotion_audits',
        },
        {
          name: 'Planogram',

          translate_key: 'planogram',
          key: 'planogram_audits',
          permission: 'planogram_audits',
        },
      ],
      smtp_data: {},
      smtp_errors: {},
      reports_errors: {},
      emails_errors: {},
      reports_data: {},
      setup_disabled: false,
      reports_disabled: false,
      emails_disabled: false,
      emails: [],
      email_data: { emails: [] },
      email_disabled: false,
      email_errors: {},
      email: '',
      users_disabled: false,
    }
  },
  watch: {
    'add_data.is_default'(val) {
      if (val) {
        this.add_data = {
          is_default: val,
        }
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['tasks']),
    filterReports() {
      return (type) => {
        const includeReports = ['osa_reports', 'performance']
        if (['monthly', 'weekly'].includes(type)) {
          return this.reports.filter((e) => includeReports.includes(e.key))
        } else {
          const mapTasks = this.tasks.map((e) => e.type)

          return this.reports
            .filter((e) => !includeReports.includes(e.key))
            .filter((e) => mapTasks.includes(e.key))
        }
      }
    },
  },
  async created() {
    await Promise.all([
      // await this.getUsers(),
      // await this.getClients(),
      // await this.getSmtp(),
      // await this.getReports(),
      // await this.getEmailUsers(),
      this.getEmails(),
      this.getSmtp(),
      this.getReports(),
    ]).finally(() => {
      this.loaded = true
    })
  },

  methods: {
    ...mapActions('getting', ['getRoute']),

    async getEmails() {
      try {
        const { result } = await this.getRoute({
          name: 'email_settings/emails',
        })
        // this.emails = result.emails?.filter((e) => e.email)
        // this.emails = result.emails || [];
        this.emails = result.emails
        this.emails_data = { emails: this.emails }
      } catch (error) {}
    },
    async getSmtp() {
      try {
        const { result } = await this.getRoute('email_settings/smtp')
        this.smtp_data = result.smtp || {}
      } catch (error) {}
    },
    async getReports() {
      try {
        const { result } = await this.getRoute('email_settings/reports')
        this.reports_data = result || {}
      } catch (error) {}
    },

    async updateEmailSetup() {
      try {
        this.setup_disabled = true
        this.smtp_errors = {}
        const { data } = await this.axios.post('email_settings/smtp/update', {
          ...this.smtp_data,
        })

        this.createAlert(data?.message)
        this.smtp_data = data.result?.smtp
        this.smtp_errors = {}
      } catch (error) {
        const err = error?.response
        if (!err) {
          this.createAlert('there is something went wrong', 'error')
          return
        }
        const responseError = err?.data?.message
        if (typeof responseError === 'object') {
          this.smtp_errors = responseError
        } else {
          this.createAlert(responseError, 'error')
        }
      } finally {
        this.setup_disabled = false
      }
    },
    async updateEmailReports() {
      try {
        this.reports_disabled = true
        this.reports_errors = {}
        const { data } = await this.axios.post(
          'email_settings/reports/update',
          {
            ...this.reports_data,
          }
        )

        this.createAlert(data?.message)
        this.reports_data = data.result
        this.reports_errors = {}
      } catch (error) {
        const err = error?.response
        if (!err) {
          this.createAlert('there is something went wrong', 'error')
          return
        }
        const responseError = err?.data?.message
        if (typeof responseError === 'object') {
          this.reports_errors = responseError
        } else {
          this.createAlert(responseError, 'error')
        }
      } finally {
        this.reports_disabled = false
      }
    },
    async updateEmailUsers() {
      try {
        this.emails_disabled = true
        this.emails_errors = {}
        const { data } = await this.axios.post('email_settings/emails/update', {
          ...this.emails_data,
        })

        this.createAlert(data?.message)
        this.getEmails()
        this.emails_errors = {}
      } catch (error) {
        const err = error?.response
        if (!err) {
          this.createAlert('there is something went wrong', 'error')
          return
        }
        const responseError = err?.data?.message
        if (typeof responseError === 'object') {
          this.emails_errors = responseError
        } else {
          this.createAlert(responseError, 'error')
        }
      } finally {
        this.emails_disabled = false
      }
    },
    async updateEmails() {
      try {
        this.email_disabled = true
        this.email_errors = {}
        const { data } = await this.axios.post('email_settings/emails/add', {
          emails: this.email_data?.emails?.map((e) => e.text),
        })

        this.createAlert(data?.message)
        this.getEmails()
        this.email_errors = {}
        this.email_data = { emails: [] }
      } catch (error) {
        const err = error?.response
        if (!err) {
          this.createAlert('there is something went wrong', 'error')
          return
        }
        const responseError = err?.data?.message
        if (typeof responseError === 'object') {
          this.email_errors = responseError
        } else {
          this.createAlert(responseError, 'error')
        }
      } finally {
        this.email_disabled = false
      }
    },

    handleUserChange(e, type, id) {
      const value = e.target.value
      const isChecked = e.target.checked
      const item = this.emails_data.emails.find((u) => u.id === id)
      const index = this.emails_data.emails.findIndex((u) => u.id === id)
      let newObj = {
        id,
        daily: 0,
        monthly: 0,
        weekly: 0,
      }

      if (!item && !type) {
        if (isChecked) {
          Object.assign(newObj, {
            daily: 1,
            monthly: 1,
            weekly: 1,
          })
          this.emails_data.emails.push(newObj)
          return
        }
        this.$set(
          this.emails_data,
          'emails',
          this.emails_data.emails.filter((u) => u.id !== id)
        )
        return
      } else if (item && !type) {
        this.$set(
          this.emails_data,
          'emails',
          this.emails_data.emails.filter((u) => u.id !== id)
        )
        return
      }

      if (isChecked) {
        if (!item) {
          Object.assign(newObj, {
            [type]: 1,
          })
          this.emails_data.emails.push(newObj)
          return
        }
        this.$set(this.emails_data.emails[index], type, 1)

        return
      }

      if (!item) {
        Object.assign(newObj, {
          [type]: 0,
        })
        this.emails_data.emails.push(newObj)
        return
      }
      this.$set(this.emails_data.emails[index], type, 0)

      // remove current user from emails if all types are 0
      const allEmpty = Object.keys(item)
        .filter((k) => ['daily', 'weekly', 'monthly'].includes(k))
        .every((k) => !item[k])
      if (allEmpty) {
        this.$set(
          this.emails_data,
          'emails',
          this.emails_data.emails.filter((u) => u.id !== id)
        )
      }
    },
    handleChangeAll(e, type) {
      const isChecked = e.target.checked
      const userIds = this.emails_data.emails.map((e) => e.id)
      const emails = this.emails
        .map(
          (user) =>
            !userIds.includes(user.id) && {
              id: user.id,
              daily: 0,
              weekly: 0,
              monthly: 0,
            }
        )
        .filter((u) => u)
      const concatUsers = new Array().concat(this.emails_data.emails, emails)

      if (isChecked) {
        if (!type) {
          this.$set(
            this.emails_data,
            'emails',
            this.emails.map((u) => ({
              id: u.id,
              daily: 1,
              weekly: 1,
              monthly: 1,
            }))
          )
        } else {
          this.$set(
            this.emails_data,
            'emails',
            concatUsers.map((u) => ({
              ...u,
              [type]: 1,
            }))
          )
        }
      } else {
        if (!type) {
          this.$set(this.emails_data, 'emails', [])
        } else {
          this.$set(
            this.emails_data,
            'emails',
            concatUsers.map((u) => ({
              ...u,
              [type]: 0,
            }))
          )

          const allEmpty = this.emails_data.emails.every(
            (c) => !c.daily && !c.weekly && !c.monthly
          )

          if (allEmpty) {
            this.$set(this.emails_data, 'emails', [])
          }
        }
      }
    },
  },
}
</script>
