<template>
  <section class="auth-bg !p-0">
    <main class="w-full lg:w-1/2 bg-black/60 backdrop-blur-lg">
      <div class="flex flex-col h-full">
        <div class="py-4 px-6">
          <div class="[margin-inline-start:auto] table">
            <t-dropdown
              :classes="{
                dropdown: '!top-full !bottom-auto bg-white',
              }"
            >
              <div
                slot="trigger"
                slot-scope="{
                  mousedownHandler,
                  focusHandler,
                  blurHandler,
                  keydownHandler,
                  isShown,
                }"
              >
                <!-- :class="{ 'border-slate-300 bg-slate-500 text-white ': isShown }" -->
                <button
                  class="py-2 px-3 rounded-md bg-transparent text-sm font-medium rtl:font-semibold border border-white text-white"
                  @mousedown="mousedownHandler"
                  @focus="focusHandler"
                  @blur="blurHandler"
                  @keydown="keydownHandler"
                >
                  <div class="w-full gap-3 sm:flex items-center hidden">
                    <i
                      class="fa-solid fa-angle-down fa-xs transition-transform ease-linear"
                      :class="{ 'rotate-180': isShown }"
                    ></i>
                    <span>{{ $t(`langs.${$i18n.locale}`) }}</span>
                  </div>
                  <div class="block sm:hidden">
                    <i class="fa-solid fa-globe fa-sm text-slate-600"></i>
                  </div>
                </button>
              </div>

              <div
                slot-scope="{ hide, blurHandler }"
                class="top-full bottom-auto"
              >
                <ul class="list-none overflow-hidden">
                  <li
                    v-for="(item, index) in langs"
                    :key="index"
                    class="border-b border-slate-100 last:border-0"
                  >
                    <button
                      class="w-full p-3 flex items-center gap-4 font-medium rtl:font-semibold text-sm hover:bg-slate-100"
                      @click.prevent="
                        () => {
                          changeLang(item.lang)
                          hide()
                          blurHandler()
                        }
                      "
                    >
                      <span
                        :class="`fi-${item.flag} w-5 h-4 bg-contain bg-no-repeat`"
                      ></span>
                      <span>{{ $t(`langs.${item.lang}`) }}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </t-dropdown>
          </div>
        </div>
        <div
          class="p-10 py-20 flex-1 flex items-center justify-center flex-col max-w-2xl mx-auto w-full"
        >
          <div class="table mx-auto mb-6">
            <img
              src="@/assets/images/logo.png"
              alt="site logo"
              class="max-w-[10rem]"
            />
          </div>
          <p class="text-white text-lg font-medium rtl:font-semibold">
            {{ $t('not_member') }}
            <router-link class="text-red-500 underline" to="/join">
              {{ $t('join_request') }}
            </router-link>
          </p>
          <div class="my-6" v-if="error">
            <p class="text-white font-medium text-sm text-center">
              {{ error }}
            </p>
          </div>
          <form class="w-full space-y-3 mt-6">
            <div class="space-y-2 form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="username"
                  id="username"
                  spellcheck="off"
                  placeholder="example@example.com"
                  v-model.trim="add_data.username"
                  required
                />
                <label for="username" class="text-white">{{
                  $t('username')
                }}</label>
              </div>
              <p
                class="text-white font-medium text-xs"
                v-if="add_errors.username"
              >
                {{ add_errors.username.join(' ') }}
              </p>
            </div>
            <div class="space-y-2 form-group">
              <div class="with-label">
                <div class="with-icon">
                  <input
                    autocomplete="off"
                    type="password"
                    name="password"
                    id="password"
                    spellcheck="off"
                    placeholder="••••••••"
                    required
                    v-model.trim="add_data.password"
                  />
                  <button
                    class="input-icon"
                    @click.prevent="textPasswordType"
                    type="button"
                  >
                    <i class="fa-solid fa-eye-slash"></i>
                  </button>
                </div>
                <label for="password" class="text-white">{{
                  $t('password')
                }}</label>
              </div>
              <p
                class="text-white font-medium text-xs"
                v-if="add_errors.password"
              >
                {{ add_errors.password.join(' ') }}
              </p>
            </div>

            <div class="space-y-3 !mt-6">
              <button
                class="w-full h-auto bg-secondary text-white font-medium rtl:font-semibold text-sm rounded-md text-center py-3 px-4 flex items-center gap-2 justify-center"
                @click.prevent="handleLogin"
                :disabled="add_disabled"
              >
                <spinner
                  size="w-4 h-4"
                  class="inline-block align-sub"
                  v-if="add_disabled"
                />

                {{ $t('login') }}
              </button>
            </div>
          </form>
        </div>
        <!-- <Footer class="border-t border-t-slate-100" /> -->
        <div class="w-full py-4 px-6 border-t border-t-white/5">
          <p
            class="text-white text-xs font-medium rtl:font-semibold text-center"
          >
            {{ new Date().getFullYear() }} © {{ $t('copyRight') }}
            <a
              href="https://Merchandise.com"
              class="text-red-500 underline hover:no-underline"
              >Merchandise - مارشيندايز</a
            >
          </p>
        </div>
      </div>
    </main>
  </section>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

// Import Swiper styles
import 'swiper/css/swiper.min.css'
import Footer from '@/components/layout/Footer.vue'
export default {
  name: 'LoginPage',
  components: {
    Swiper,
    SwiperSlide,
    Footer,
  },
  data() {
    return {
      add_data: {},
      add_errors: {},
      error: null,
      add_disabled: false,
    }
  },

  computed: {
    langs() {
      const mapLanguages = this.$i18n.availableLocales.map((e) => {
        const iso = { ar: 'sa', en: 'us' }
        return { lang: e, flag: iso[e] }
      })
      return mapLanguages
    },
  },

  methods: {
    async handleLogin() {
      this.add_disabled = true
      this.add_errors = {}
      this.error = null
      const { redirect } = this.$route.query
      try {
        const { data } = await this.axios.post('login', this.add_data)
        this.$store.commit('auth/SET_USER', {
          profile: data.result.profile,
          access_token: data.result.access_token,
        })
        this.$cookies.set('t', data.result.access_token)
        // this.$router.push(redirect ? redirect : '/')
        window.location.reload()
      } catch (error) {
        const err = error?.response
        if (!err) {
          this.createAlert('there is something went wrong', 'error')
          return
        }
        const responseError = err?.data?.message
        if (typeof responseError === 'object') {
          this.add_errors = responseError
        } else {
          this.error = responseError
        }
      } finally {
        this.add_disabled = false
      }
    },
  },
}
</script>
