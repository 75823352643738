<template>
  <aside
    class="h-screen fixed top-0 z-30 w-full bg-slate-700/30 side-container"
    ref="sideBar"
  >
    <div
      class="h-full flex flex-col overflow-x-hidden min-h-screen max-h-screen bg-slate-700"
      data-type="sidebar"
    >
      <header class="relative">
        <div class="w-full border-b border-slate-600 p-4 flex items-center">
          <div class="flex-1">
            <img
              src="@/assets/images/horizontal logo.png"
              alt="logo image"
              class="max-h-[3rem] object-contain table"
            />
          </div>
          <div>
            <button class="text-white w-5 h-5" @click.prevent="openCloseSide">
              <i class="fa-solid fa-times fa-sm"></i>
            </button>
          </div>
        </div>
      </header>
      <nav class="w-full flex-1 overflow-y-auto">
        <ul class="list-none w-full p-2">
          <li
            v-for="(item, index) in pages"
            :key="index"
            class="animate-item-move"
          >
            <router-link
              :to="item.path"
              class="w-full flex items-center gap-4 text-white p-3 px-4"
              v-if="!item.child"
            >
              <div>
                <i :class="item.icon"></i>
              </div>
              <div class="flex-1 grid">
                <p class="truncate text-sm font-medium rtl:font-semibold">
                  {{ $t(item.key) }}
                </p>
              </div>
            </router-link>
            <div v-else>
              <a href="#" class="w-full flex items-center gap-4 text-white p-3">
                <div class="flex-1 grid">
                  <p
                    class="truncate text-xs font-medium rtl:font-semibold text-slate-400"
                  >
                    {{ $t(item.key) }}
                  </p>
                </div>
              </a>
              <transition name="move">
                <ul class="list-none space-y-1">
                  <li v-for="(child, idx) in item.child" :key="idx">
                    <router-link
                      class="w-full flex items-center gap-4 text-white p-2.5 px-4 text-sm"
                      :to="child.path"
                    >
                      <div>
                        <i :class="child.icon"></i>
                      </div>
                      <div class="flex-1 grid">
                        <p
                          class="truncate text-sm font-medium rtl:font-semibold"
                        >
                          {{ $t(child.key) }}
                        </p>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </transition>
            </div>
          </li>
          <!-- <li v-if="hasNoPermissions">
            <p class="text-white text-sm text-center">
              You do not have any roles to reach forgiveness and start
              procedures. Contact admin to add some roles to you . You do not
              have any roles to reach forgiveness and start procedures. Contact
              admin to add some roles to you
            </p>
          </li> -->
        </ul>
      </nav>
      <footer class="p-4">
        <button
          class="w-full flex items-center gap-4 text-white p-3 px-4 rounded-md bg-slate-600 text-left rtl:text-right"
          @click.prevent="logout"
        >
          <div>
            <i class="fa-solid fa-power-off"></i>
          </div>
          <div class="flex-1 grid">
            <p class="truncate text-sm font-medium rtl:font-semibold">
              {{ $t('logout') }}
            </p>
          </div>
        </button>
      </footer>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SideBar',
  data() {
    return {
      hasData: false,
    }
  },
  watch: {
    $route() {
      // close sidebar
      const sidebar = this.$refs.sideBar
      if (sidebar.classList.contains('side-active')) {
        sidebar.classList.remove('side-active')
        this.tree = null
      }
    },
    tree() {
      const sidebar = this.$el
      const asideMove = document.querySelectorAll('.aside-move')
      if (this.tree) {
        if (!sidebar.classList.contains('side-active')) {
          sidebar.classList.add('side-active')
          asideMove.forEach((e) => e.classList.add('current-aside-active'))
        }
      }
    },
    getUser(val) {
      if (val) {
        this.hasData = true
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['permissions', 'getUser', 'tasks']),
    hasNoPermissions() {
      const permissions = this.permissions
      const pagesPermissionsRegex = new RegExp(
        `^(show|add|delete|edit) ${this.pages
          .map((e) => e.permission)
          .join('|')}`,
        'gi'
      )
      const isNotMatch = permissions.some((e) => pagesPermissionsRegex.test(e))
      return !isNotMatch
    },
    pages() {
      let result = []
      const user = this.getUser
      const permissions = this.permissions
      const pages = [
        {
          name: 'Dashboard',
          icon: 'fa-solid fa-home',
          path: '/',
          key: 'dashboard',
          permission: 'dashboard',
        },
        {
          name: 'Calendar',
          path: '/calendar',
          icon: 'fa-solid fa-calendar-days',
          key: 'calendar',
          permission: 'calendar',
        },
        {
          name: 'Performance',
          path: '/performance',
          icon: 'fa-solid fa-user-group',
          key: 'performance',
          permission: 'performance',
        },
        // {
        //   name: 'OSA Report',
        //   path: '/osa-reports',
        //   icon: 'fa-solid fa-arrow-trend-up',
        //   key: 'osa_reports',
        //   permission: 'osa_reports',
        // },
        {
          name: 'Urgent visit',
          path: '/reports/visits/urgent-visit',
          icon: 'fa-solid fa-calendar-plus',
          key: 'urgent_visit',
          permission: 'urgent_visit',
        },

        {
          name: 'Reports',
          key: 'reports',
          permission:
            'visits|sku_audits|expiry_audits|stock_audits|shelf_audits|delivery_audits|return_audits|promotion_audits|planogram_audits',
          child: [
            {
              name: 'Visits',
              path: '/visits',
              icon: 'fa-solid fa-list',
              key: 'visits',
              permission: 'visits',
            },
            {
              name: 'Skus',
              path: '/reports/skus-audits',
              icon: 'fa-solid fa-barcode',
              key: 'skus',
              permission: 'sku_audits',
            },
            {
              name: 'Expires',
              path: '/reports/expiry-audits',
              icon: 'fa-solid fa-arrow-trend-down',
              key: 'expiries',
              permission: 'expiry_audits',
            },

            {
              name: 'Stock ',
              path: '/reports/stock-audits',
              icon: 'fa-solid fa-cubes',
              key: 'stock_audits',
              permission: 'stock_audits',
            },
            {
              name: 'Delivery',
              path: '/reports/delivery-audits',
              icon: 'fa-solid fa-motorcycle',
              key: 'delivery_audits',
              permission: 'delivery_audits',
            },

            {
              name: 'Shelf ',
              path: '/reports/shelf-audits',
              icon: 'fa-solid fa-table-list',
              key: 'shelf_audits',
              permission: 'shelf_audits',
            },
            {
              name: 'Returns',
              path: '/reports/returns-audits',
              icon: 'fa-solid fa-rotate-left',
              key: 'returns',
              permission: 'return_audits',
            },
            {
              name: 'Promotion',
              path: '/reports/promotion-audits',
              icon: 'fa-solid fa-panorama',
              key: 'promotion',
              permission: 'promotion_audits',
            },
            {
              name: 'Planogram',
              path: '/reports/planogram-audits',
              icon: 'fa-solid fa-clone',
              key: 'planogram',
              permission: 'planogram_audits',
            },
          ],
        },

        // {
        //   name: 'Messages',
        //   icon: 'fa-solid fa-message',
        //   key: '',
        //   permission: '',
        //   path: '/messages',
        // },
        {
          name: 'setup',
          key: 'setup',
          permission:
            'journey_plans|configuration|skus|shifts|zones|categories|sub_categories|company_stores|company_branches|brands|units|shelves|planograms|store_placements',
          child: [
            {
              name: 'Journey plans',
              path: '/setup/journey-plans',
              icon: 'fa-solid fa-calendar-week',
              key: 'journey_plans',
              permission: 'journey_plans',
            },
            {
              name: 'Configuration',
              path: '/setup/configuration/brands',
              icon: 'fa-solid fa-store',
              key: 'configuration',
              permission:
                'categories|sub_categories|company_stores|company_branches|brands|units|shelves|planograms|store_placements|competitors',
            },
            {
              name: 'SKUs',
              path: '/setup/skus',
              icon: 'fa-solid fa-barcode',
              key: 'skus',
              permission: 'skus',
            },
            {
              name: 'Shifts',
              path: '/setup/shifts',
              icon: 'fa-solid fa-clock',
              key: 'shifts',
              permission: 'shifts',
            },
            // {
            //   name: 'Zones',
            //   path: '/setup/zones',
            //   icon: 'fa-solid fa-street-view',
            //   key: 'zones',
            //   permission: 'zones',
            // },
            {
              name: 'Locations',
              path: '/setup/locations/region-groups',
              icon: 'fa-solid fa-location-pin',
              key: 'locations',
              permission: 'locations',
            },
            {
              name: 'Email configurations',
              path: '/setup/email-configurations',
              icon: 'fa-solid fa-screwdriver-wrench',
              key: 'email_configurations',
              permission: 'email_configurations',
            },
          ],
        },
        {
          name: 'administration',
          key: 'administration',
          permission: 'users|clients|merchandisers|roles',
          child: [
            {
              name: 'users',
              path: '/setup/users',
              icon: 'fa-solid fa-user-group',
              key: 'users',
              permission: 'users',
            },
            {
              name: 'clients',
              path: '/setup/clients',
              icon: 'fa-solid fa-users-line',
              key: 'clients',
              permission: 'clients',
            },
            {
              name: 'merchandisers',
              path: '/setup/merchandisers',
              icon: 'fa-solid fa-user-tie',
              key: 'merchandisers',
              permission: 'merchandisers',
            },
            {
              name: 'Roles',
              path: '/setup/roles',
              icon: 'fa-solid fa-user-tie',
              key: 'roles',
              permission: 'roles',
            },
          ],
        },
      ]
      result = !['client_user', 'supervisor'].includes(user.role_type)
        ? pages
        : pages.filter((e) => !['setup', 'administration'].includes(e.key))
      return permissions.length
        ? result.filter((e) => {
            const regex = new RegExp('^(show) ' + e.permission, 'gi')
            const hasPermission = permissions.some((permission) =>
              regex.test(permission)
            )

            if (!hasPermission) return

            if (/\w+_audits/g.test(e.permission)) {
              if (this.tasks.some((task) => regex.test(task.type))) {
                return this.FilterPermissions(e)
              }
            } else {
              return this.FilterPermissions(e)
            }

            return false
          })
        : result
    },
  },
  created() {
    window.addEventListener('click', (e) => {
      const sidebar = this.$refs.sideBar
      if (e.target === sidebar) {
        sidebar.classList.toggle('side-active')
      }
    })
  },
  methods: {
    openCloseSide() {
      const sidebar = this.$refs.sideBar
      sidebar.classList.toggle('side-active')
    },
    FilterPermissions(item) {
      let result
      if (!item.child) return item
      result = item.child.filter((child) => {
        const regex = new RegExp('^(show) ' + child.permission, 'gi')
        const hasPermission = this.permissions.some((permission) =>
          regex.test(permission)
        )

        if (/\w+_audits/g.test(child.permission)) {
          if (
            this.tasks.some((task) =>
              new RegExp(child.permission, 'gi').test(task.type)
            )
          ) {
            return child
          }
          return
        }

        if (!hasPermission) return false

        return child
      })

      item.child = result
      return item
    },
  },
}
</script>
