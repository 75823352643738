<template>
  <footer class="w-full py-4 px-6">
    <div
      class="w-full flex justify-between items-center gap-4"
      data-type="centered-item"
    >
      <div class="flex-1">
        <p class="text-slate-600 text-xs font-medium rtl:font-semibold">
          {{ new Date().getFullYear() }} © {{ $t('copyRight') }}
          <a
            href="https://Merchandise.com"
            class="text-primary underline hover:no-underline"
            >Merchandise - مارشيندايز</a
          >
        </p>
      </div>
      <div v-if="['Login', 'Join'].includes($route.name)">
        <t-dropdown>
          <div
            slot="trigger"
            slot-scope="{
              mousedownHandler,
              focusHandler,
              blurHandler,
              keydownHandler,
              isShown,
            }"
          >
            <button
              class="py-2 px-3 rounded-md bg-slate-50 hover:bg-slate-100 text-sm font-medium rtl:font-semibold"
              :class="{ 'border-slate-300 bg-slate-500 text-white ': isShown }"
              @mousedown="mousedownHandler"
              @focus="focusHandler"
              @blur="blurHandler"
              @keydown="keydownHandler"
            >
              <div class="w-full gap-3 sm:flex items-center hidden">
                <i
                  class="fa-solid fa-angle-down fa-xs transition-transform ease-linear"
                  :class="{ 'rotate-180': isShown }"
                ></i>
                <span>{{ $t(`langs.${$i18n.locale}`) }}</span>
              </div>
              <div class="block sm:hidden">
                <i class="fa-solid fa-globe fa-sm text-slate-600"></i>
              </div>
            </button>
          </div>

          <div slot-scope="{ hide, blurHandler }">
            <ul class="list-none overflow-hidden">
              <li
                v-for="(item, index) in langs"
                :key="index"
                class="border-b border-slate-100 last:border-0"
              >
                <button
                  class="w-full p-3 flex items-center gap-4 font-medium rtl:font-semibold text-sm hover:bg-slate-100"
                  @click.prevent="
                    () => {
                      changeLang(item.lang)
                      hide()
                      blurHandler()
                    }
                  "
                >
                  <span
                    :class="`fi-${item.flag} w-5 h-4 bg-contain bg-no-repeat`"
                  ></span>
                  <span>{{ $t(`langs.${item.lang}`) }}</span>
                </button>
              </li>
            </ul>
          </div>
        </t-dropdown>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    langs() {
      const mapLanguages = this.$i18n.availableLocales.map((e) => {
        const iso = { ar: 'sa', en: 'us' }
        return { lang: e, flag: iso[e] }
      })
      return mapLanguages
    },
  },
}
</script>
