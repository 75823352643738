import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import securePlugin from './plugins/plugin'

Vue.use(Vuex)
// https://www.getpostman.com/collections/e1e0f942decbb45d60b3
export default new Vuex.Store({
  state: {
    load: false,
    window_width: null,
    map_api_key: 'AIzaSyBL9zlkm2YRyU_hN1yMGrVJ6_JlEHCzzig',
    BASE_URL: 'https://apis-mms.brmaja.host/portal/',
  },
  getters: {},
  mutations: {
    SET_LOAD(state, data) {
      state.load = data
    },
    SET_WIDTH(state, data) {
      state.window_width = data
    },
  },
  actions: {},
  modules: { ...modules },
  plugins: [securePlugin],
})
